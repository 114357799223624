[
  {
    "id": "c1d9a0b1-8b9a-417f-81dc-0762e13239c7",
    "description": "Ramassage de recyclage ",
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "code": "MTL00003",
    "isActive": true,
    "synchronizationAt": "0"
  },
  {
    "id": "9077cd8b-98dd-47ae-a0d1-1170d5dac2b5",
    "description": "Projet a l'étranger - OSBL",
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "code": "OSBL0001",
    "isActive": true,
    "synchronizationAt": "0"
  },
  {
    "id": "47f13932-c58f-4a09-82d5-12460e094697",
    "description": "Spring Meeting",
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "code": "7555",
    "isActive": true,
    "synchronizationAt": "0"
  },
  {
    "id": "aa8b1985-65c8-4aee-ad32-198e6b7f9011",
    "description": "Fall Meeting",
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "code": "7650",
    "isActive": true,
    "synchronizationAt": "0"
  },
  {
    "id": "8edd24ce-77d5-4ca5-99a4-1aa571956442",
    "description": "Réfection - Ingénierie",
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "code": "PJT0002",
    "isActive": true,
    "synchronizationAt": "0"
  },
  {
    "id": "16073b67-baa7-4937-be44-1bc5590f7979",
    "description": "Autoroute 19 (Laval)-ING.",
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "code": "QC00004",
    "isActive": true,
    "synchronizationAt": "0"
  },
  {
    "id": "15612594-54d7-42f8-813d-251889b40172",
    "description": "DDC",
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "code": "00017",
    "isActive": true,
    "synchronizationAt": "0"
  },
  {
    "id": "808e1413-b587-4eca-a13f-37585bec6146",
    "description": "Proposal/ Offre de service",
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "code": "_OFS",
    "isActive": true,
    "synchronizationAt": "0"
  },
  {
    "id": "94a3c05a-b655-4432-95e6-4e7f9ac7f2a5",
    "description": "Implemetation / Implantation",
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "code": "MTL00002",
    "isActive": true,
    "synchronizationAt": "0"
  },
  {
    "id": "87550c6f-e03e-4658-acf3-4f4f0c7624c3",
    "description": "Projet Implantation CRM",
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "code": "00020",
    "isActive": true,
    "synchronizationAt": "0"
  },
  {
    "id": "6b065ad8-8833-4999-81ed-1693e0967030",
    "description": "Analyse de faisabilité",
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "code": "00012",
    "isActive": true,
    "synchronizationAt": "0"
  },
  {
    "id": "7d37bb68-0958-4e0f-83a9-78d565e71a77",
    "description": "Contract de recyclage Candiac",
    "code": "00006",
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "isActive": true,
    "synchronizationAt": "0"
  },
  {
    "id": "b17049c5-13d3-4807-9bf8-61f390e095e6",
    "description": "Administration",
    "code": "_ADMIN",
    "isActive": true,
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "synchronizationAt": "0"
  },
  {
    "id": "0991e86c-dd11-4640-8885-3743ffd61ee6",
    "description": "Migration -Placement Personnel",
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "code": "PJT0004",
    "isActive": true,
    "synchronizationAt": "0"
  },
  {
    "id": "3af4c973-1228-49fa-82f4-6cc04c372f2d",
    "description": "Pavage Tunnel ville marie",
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "code": "00015",
    "isActive": true,
    "synchronizationAt": "0"
  },
  {
    "id": "6c63ec13-ef49-44b9-8531-002f7139e947",
    "description": "Contrat de construction",
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "code": "00001",
    "isActive": true,
    "synchronizationAt": "0"
  },
  {
    "id": "fd24addd-dad0-4335-b652-7dbdcc3176e8",
    "description": "Risk Management",
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "code": "MTL00002",
    "isActive": true,
    "synchronizationAt": "0"
  },
  {
    "id": "1b6cadbb-082b-4580-a4b5-77f47dc91c90",
    "description": "PTO /Temps non travailler",
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "code": "_TNT",
    "isActive": true,
    "synchronizationAt": "0"
  },
  {
    "id": "96134ab1-c016-4956-8482-84be315c4cec",
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "description": "Projet",
    "code": "POP00001",
    "isActive": true,
    "synchronizationAt": "0"
  },
  {
    "id": "b5794ca7-93b7-47fc-94b8-860de063fcf2",
    "description": "Detailed Eng / Ing. détaillée",
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "code": "PJT0001",
    "isActive": true,
    "synchronizationAt": "0"
  },
  {
    "id": "5753ca3c-b864-4eec-8657-91febd16e86d",
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "description": "Analyse de durabiliité",
    "code": "00008",
    "isActive": true,
    "synchronizationAt": "0"
  },
  {
    "id": "00cbbbac-b0e2-4acb-a597-92253d5575cb",
    "description": "Modification sur 3ieme étage",
    "code": "00002",
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "isActive": true,
    "synchronizationAt": "0"
  },
  {
    "id": "2b1ff0c6-37dd-4aa5-bc2f-96a420ff0b6f",
    "description": "Business dev/ Dev des affaires",
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "code": "_DDA",
    "isActive": true,
    "synchronizationAt": "0"
  },
  {
    "id": "c78b2037-ed87-4296-97cf-9a186692b90c",
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "description": "Analyse de faisabilité",
    "code": "00018",
    "isActive": true,
    "synchronizationAt": "0"
  },
  {
    "id": "95a78120-8c65-459a-bf26-a3222d8b5f04",
    "description": "Ing detaillé",
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "code": "PJT0010",
    "isActive": true,
    "synchronizationAt": "0"
  },
  {
    "id": "e15f6de8-dda4-4573-bde2-a4f667679f32",
    "description": "DDC #1",
    "code": "00009",
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "isActive": true,
    "synchronizationAt": "0"
  },
  {
    "id": "a7ac49d4-9717-4e33-969c-a83e2b891afd",
    "description": "Analyses et tests",
    "code": "00014",
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "isActive": true,
    "synchronizationAt": "0"
  },
  {
    "id": "2ae3ddd4-43f4-4a9c-aa24-aba7f4e36b2d",
    "description": "Training /Formation",
    "code": "_FORM",
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "isActive": true,
    "synchronizationAt": "0"
  },
  {
    "id": "6cf1a941-7ff7-4103-b425-b21539d912a8",
    "description": "Ingénierie détaillée",
    "code": "ZZMODELE",
    "isActive": true,
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "synchronizationAt": "0"
  },
  {
    "id": "2a287667-1319-4b33-a803-b8e082012b80",
    "description": "Syteme HVAC -.Service conseil",
    "code": "PJT0005",
    "isActive": true,
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "synchronizationAt": "0"
  },
  {
    "id": "93665d31-2648-4136-9911-c37a2754d9aa",
    "description": "Bureau Delson",
    "code": "00005",
    "isActive": true,
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "synchronizationAt": "0"
  },
  {
    "id": "30666fca-938d-4982-9f19-c68a5819e3c3",
    "description": "Nouveau contrat de recyclage",
    "code": "00000",
    "isActive": true,
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "synchronizationAt": "0"
  },
  {
    "id": "4748ead0-88bf-429b-a9cc-d0abd9a840e9",
    "description": "Impl. Development Développement",
    "code": "MTL00003",
    "isActive": true,
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "synchronizationAt": "0"
  },
  {
    "id": "38be7414-3eb0-43bc-9e16-d5e427f68c97",
    "description": "Eng. Tunnel Louis-HL - Ing.",
    "code": "PJT0003",
    "isActive": true,
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "synchronizationAt": "0"
  },
  {
    "id": "14170f6d-5bf3-40b3-8ca5-e5be16cdaf9b",
    "description": "Bridge /Pont HM",
    "code": "MTL00005",
    "isActive": true,
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "synchronizationAt": "0"
  },
  {
    "id": "f72ae2cc-9076-4a06-b8b2-fcdbf471b292",
    "description": "Support Yearly / Annuel",
    "code": "MTL00004",
    "isActive": true,
    "departmentId":"00d9a0b1-8b9a-417f-81dc-0762e13239c3",
    "synchronizationAt": "0"
  }
]
