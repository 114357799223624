import { LicenseManager } from '@ag-grid-enterprise/core';
import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter } from '@angular/router';
import { HttpMockRequestInterceptor } from '@core/interceptors/http-mock.interceptor';
import { getEnvironmentProviders, getHttpClientProvider, getPortalProviders } from '@portal-shared/main';
import { AllyaSettingService } from '@services/allya-setting.service';
import { DraggingService } from '@services/dragging.service';
import { PeriodService } from '@services/period.service';
import { StartupService } from '@services/startup.service';
import { routes } from './app/app-routing';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

if (environment.build === 'prod') {
  enableProdMode();
}

//needs to be initialized in main boot file.
LicenseManager.setLicenseKey(environment.agGridLicenseKey);

bootstrapApplication(AppComponent, {
  providers: [
    getEnvironmentProviders(environment),
    ...getPortalProviders(environment, DraggingService, AllyaSettingService, StartupService, PeriodService),
    getHttpClientProvider([HttpMockRequestInterceptor]),
    provideRouter(routes)
  ]
}).catch(err => console.error(err));

