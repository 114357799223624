import { Component } from '@angular/core';
import { FontAwesomeService } from '@core/services/font-awesome.service';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { AppComponent as PortalAppComponent } from '@portal-shared/app/app.component';
import { AllyaLogoComponent } from '@portal-shared/app/components/logos/allya-logo/allya-logo.component';

@Component({
  standalone: true,
  selector: 'al-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [PortalAppComponent, AllyaLogoComponent]
})
export class AppComponent {
  public constructor(faIconLibrary: FaIconLibrary) {
    FontAwesomeService.Configure(faIconLibrary);
  }
}
