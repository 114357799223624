
import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BaseComponent } from '@portal-shared/app/components/base-components/base.component';
import { ResponsiveDirective } from '@portal-shared/app/directives/responsive.directive';
import { Confirmation } from '../../models/confirmation.model';
import { ConfirmationService } from '../../services/confirmation.service';

@Component({
  standalone: true,
  selector: 'portal-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
  imports: [TranslateModule, ResponsiveDirective]
})
export class ConfirmationComponent extends BaseComponent implements OnInit {
  public confirmation?: Confirmation;

  public constructor(private confirmationService: ConfirmationService) {
    super();
  }

  public ngOnInit() {
    this.subscribe(
      this.confirmationService.observe().subscribe(confirmation => {
        this.confirmation = confirmation;
      })
    );
  }

  public confirm() {
    this.confirmationService.accept();
  }
  public cancel() {
    this.confirmationService.cancel();
  }
}
