export class MigrationError extends Error {
  /**
   *
   */
  public constructor(message: string, error: Error) {
    super(`${message}: ${error.message}`);
    this.name = MigrationError.name;
    this.stack = error.stack;
  }
}
