import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { GraphApiRequestService } from '@portal-shared/app/services/graph-api.service';
import { LoginService } from '@portal-shared/app/services/login.service';
import { from, switchMap } from 'rxjs';

export const AuthInterceptor: HttpInterceptorFn = (req, next) => {
  const loginService = inject(LoginService);

  const isApiCall = req.url.includes('api');
  const isGraphCall = req.url.includes(GraphApiRequestService.ENDPOINT);

  if (!isApiCall && !isGraphCall) {
    // Means it is a relative request to fetch local assets like i18n or settings files.
    return next(req);
  }

  const tokenPromise = isGraphCall ? loginService.getGraphApiToken() : loginService.getApplicationToken();

  return from(tokenPromise).pipe(
    switchMap(token => {
      if (!token) {
        return next(req);
      }

      const headers = req.headers.set('Authorization', `Bearer ${token}`);

      const withCredentials = !req.url.startsWith(GraphApiRequestService.ENDPOINT);
      const requestClone = req.clone({ headers, withCredentials });
      return next(requestClone);
    })
  );
};
