import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, HostBinding, HostListener, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BaseComponent } from '@portal-shared/app/components/base-components/base.component';
import { ToastService } from '@portal-shared/app/services/toast.service';
import { ContextMenuService } from './context-menu.service';
import { ContextMenuItem } from './models/context-menu-item';

@Component({
  standalone: true,
  selector: 'portal-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
  imports: [AsyncPipe, TranslateModule, NgTemplateOutlet]
})
export class ContextMenuComponent extends BaseComponent implements OnInit {
  public isOpen = false;
  public isLoading = false;

  public items: ContextMenuItem[] = [];

  public constructor(
    private elementRef: ElementRef<HTMLElement>,
    private changeDetector: ChangeDetectorRef,
    private contextMenuService: ContextMenuService,
    private toastService: ToastService
  ) {
    super();
  }

  public ngOnInit() {
    this.subscribe(
      this.contextMenuService.observeContextMenu().subscribe(menu => {
        this.items = menu.items.slice();

        this.positionMenu(menu);
        if (menu.isOpen) {
          this.displayMenu();
        } else {
          this.hideMenu();
        }
      })
    );
  }

  @HostBinding('style.top.px')
  public top = 0;
  @HostBinding('style.left.px')
  public left = 0;

  @HostListener('document:contextmenu', ['$event'])
  public onContextMenu(event: PointerEvent) {
    this.contextMenuService.onContextMenu(event);
  }

  @HostListener('document:click')
  public onDocumentClick() {
    this.contextMenuService.closeMenu();
  }

  private displayMenu() {
    this.isOpen = true;
  }
  private hideMenu() {
    this.isOpen = false;
  }

  private positionMenu({ left, top }: { left: number; top: number }) {
    this.top = 0;
    this.left = 0;
    this.changeDetector.detectChanges();

    const bounds = this.elementRef.nativeElement.getBoundingClientRect();
    const bodyBounds = document.body.getBoundingClientRect();

    if (left + bounds.width > bodyBounds.right) {
      left = left - bounds.width;
    }
    if (top + bounds.height > bodyBounds.bottom) {
      top = top - bounds.height;
    }
    this.top = top;
    this.left = left;
    this.changeDetector.detectChanges();
  }

  public async itemClick(event: MouseEvent, item: ContextMenuItem) {
    event.preventDefault();
    event.stopPropagation();
    this.isLoading = true;
    try {
      await item.click();
    } catch (error) {
      this.toastService.displayError(error as Error);
    } finally {
      this.isLoading = false;
      this.contextMenuService.closeMenu();
    }
  }
}
