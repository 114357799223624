import { PeriodDto } from '@portal-shared/app/dtos/period.dto';
import { DateTime } from 'luxon';

const firstPeriodStart = DateTime.now().startOf('week').minus({ week: 1 });
const firstPeriodEnd = DateTime.now().endOf('week').minus({ week: 1 });
const numberOfPeriods = 5;

export const periodsMock: PeriodDto[] = [...Array<PeriodDto>(numberOfPeriods)].map((_, i) => {
  return {
    id: i,
    start: firstPeriodStart.plus({ week: i }).toString(),
    end: firstPeriodEnd.plus({ week: i }).toString(),
    hasActualHours: i === 0
  };
});
