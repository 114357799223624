[
  {
    "projectId": "6b065ad8-8833-4999-81ed-1693e0967030",
    "classId": "869d0968-35fe-4ecc-8a9d-11ea24ca25ab",
    "departmentId": "8d2f479d-d910-417c-a5f8-e269123c6968",
    "budgetedHours": 150,
    "actualHours": 60,
    "plannedHours": 40
  },
  {
    "projectId": "6b065ad8-8833-4999-81ed-1693e0967030",
    "classId": "071ad0a1-a540-47f0-9f2e-1795403594e5",
    "departmentId": "8d2f479d-d910-417c-a5f8-e269123c6968",
    "budgetedHours": 150,
    "actualHours": 40,
    "plannedHours": 60
  },
  {
    "projectId": "47f13932-c58f-4a09-82d5-12460e094697",
    "classId": "071ad0a1-a540-47f0-9f2e-1795403594e5",
    "departmentId": "8d2f479d-d910-417c-a5f8-e269123c6968",
    "budgetedHours": 450,
    "actualHours": 170,
    "plannedHours": 130
  },
  {
    "projectId": "6b065ad8-8833-4999-81ed-1693e0967030",
    "classId": "7ea443d0-02fc-4cdf-b6de-1f4d53242625",
    "departmentId": "8d2f479d-d910-417c-a5f8-e269123c6968",
    "budgetedHours": 60,
    "actualHours": 10,
    "plannedHours": 30
  },
  {
    "projectId": "7d37bb68-0958-4e0f-83a9-78d565e71a77",
    "classId": "7ea443d0-02fc-4cdf-b6de-1f4d53242625",
    "departmentId": "8d2f479d-d910-417c-a5f8-e269123c6968",
    "budgetedHours": 20,
    "actualHours": -10,
    "plannedHours": -30
  },
  {
    "classId": "7ea443d0-02fc-4cdf-b6de-1f4d53242625",
    "projectId": "0991e86c-dd11-4640-8885-3743ffd61ee6",
    "departmentId": "8d2f479d-d910-417c-a5f8-e269123c6968",
    "budgetedHours": 900,
    "actualHours": 350,
    "plannedHours": 250
  },
  {
    "classId": "7ea443d0-02fc-4cdf-b6de-1f4d53242625",
    "projectId": "3af4c973-1228-49fa-82f4-6cc04c372f2d",
    "departmentId": "8d2f479d-d910-417c-a5f8-e269123c6968",
    "budgetedHours": 750,
    "actualHours": 250,
    "plannedHours": 250
  },
  {
    "classId": "7ea443d0-02fc-4cdf-b6de-1f4d53242625",
    "projectId": "6c63ec13-ef49-44b9-8531-002f7139e947",
    "departmentId": "8d2f479d-d910-417c-a5f8-e269123c6968",
    "budgetedHours": 450,
    "actualHours": 150,
    "plannedHours": 150
  },
  {
    "classId": "96a12f38-e106-4195-9ae9-3a46e41cec15",
    "projectId": "fd24addd-dad0-4335-b652-7dbdcc3176e8",
    "departmentId": "8d2f479d-d910-417c-a5f8-e269123c6968",
    "budgetedHours": 180,
    "actualHours": 40,
    "plannedHours": 80
  },
  {
    "classId": "96a12f38-e106-4195-9ae9-3a46e41cec15",
    "projectId": "7d37bb68-0958-4e0f-83a9-78d565e71a77",
    "departmentId": "8d2f479d-d910-417c-a5f8-e269123c6968",
    "budgetedHours": 0,
    "actualHours": 0,
    "plannedHours": 0
  },
  {
    "classId": "96a12f38-e106-4195-9ae9-3a46e41cec15",
    "projectId": "6b065ad8-8833-4999-81ed-1693e0967030",
    "departmentId": "8d2f479d-d910-417c-a5f8-e269123c6968",
    "budgetedHours": 1800,
    "actualHours": 450,
    "plannedHours": 750
  },
  {
    "classId": "071ad0a1-a540-47f0-9f2e-1795403594e5",
    "projectId": "a7ac49d4-9717-4e33-969c-a83e2b891afd",
    "departmentId": "8d2f479d-d910-417c-a5f8-e269123c6968",
    "budgetedHours": 1800,
    "actualHours": 600,
    "plannedHours": 600
  }
]
