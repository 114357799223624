import { DomainDatabase } from '@portal-shared/app/databases/domain.database';

export class AllyaDomainDatabase extends DomainDatabase {
  protected static override instance?: AllyaDomainDatabase;

  protected override get stores() {
    return { ...super.stores };
  }

  private constructor(domainKey: string) {
    super(domainKey);
  }

  public static initialize(domainKey: string) {
    if (!this.instance) {
      this.instance = new AllyaDomainDatabase(domainKey);
      DomainDatabase.instance = this.instance;
    }
    return this.instance;
  }

  public static override getInstance() {
    if (!this.instance) {
      throw Error('Initialize the domain database before using it.');
    }
    return this.instance;
  }
}
