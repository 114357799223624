@if (isShown && message) {
  <div
    class="portal-box toast-box toast-box-type-{{ToastType[message.type]}}"
    (click)="hide()">
    <div class="toast-box-title portal-bold">
      <fa-icon class="toast-box-title-left-icon"
      [icon]="['fas', isSuccess ? 'circle-check' : 'exclamation-triangle']"></fa-icon>
      <div class="toast-box-title-text"
      [translate]="message.titleKey"></div>
      <fa-icon [icon]="['fas', 'times-circle']"></fa-icon>
    </div>
    <div class="toast-box-body"
    [translate]="message.messageKey ?? genericMessageKey"></div>
  </div>
}
