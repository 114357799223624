import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationComponent } from '@portal-shared/app/components/confirmation/confirmation.component';
import { ContextMenuComponent } from '@portal-shared/app/components/context-menu/context-menu.component';
import { ErrorWrapperComponent } from '@portal-shared/app/components/error-wrapper/error-wrapper.component';
import { ToastComponent } from '@portal-shared/app/components/toast/toast.component';
import { LoginService } from '@portal-shared/app/services/login.service';
import { DateTime } from 'luxon';
import { PortalSettingsService } from './services/portal-settings.service';
import { StartupService } from './services/startup.service';

@Component({
  standalone: true,
  selector: 'portal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [style({ opacity: 0 }), animate(500, style({ opacity: 1 }))]),
      transition(':leave', [animate(500, style({ opacity: 0 }))])
    ])
  ],
  imports: [
    ErrorWrapperComponent,
    ContextMenuComponent,
    ConfirmationComponent,
    ToastComponent,
    RouterOutlet,
    TranslateModule
  ]
})
export class AppComponent implements OnInit {
  public starting?: boolean;
  public error?: {
    title: string;
    message?: string;
    stack?: string;
  };
  public version?: string;
  public date = DateTime.now();

  public constructor(
    private startupService: StartupService,
    private settingService: PortalSettingsService,
    private loginService: LoginService
  ) {}

  public async ngOnInit() {
    const settings = await this.settingService.getSettings();
    this.version = settings.version;

    this.startupService.observeInitializationStatus().subscribe(({ started, ended, error }) => {
      this.starting = started && !ended;
      this.error = error ? this.getErrorData(error) : undefined;
    });
  }

  private getErrorData(e?: Error) {
    const errorData = {
      title: 'App startup failed',
      message: e?.message,
      stack: e?.stack
    };

    if (!errorData.message) {
      try {
        const fallback = JSON.stringify(e);
        errorData.message = fallback;
      } catch {
        errorData.message = 'Unknown Error';
      }
    }
    return errorData;
  }

  public async retry() {
    await this.loginService.login();
    window.location.reload();
  }
}
