import { inject } from '@angular/core';
import { StartupService } from '@portal-shared/app/services/startup.service';

/**
 * Guard to ensure that the client side initialization is done before the route is activated.
 * Only unauthenticated initialization is done here.
 */
export class PublicInitializationGuard {
  private static async isInitialized(startupService = inject(StartupService)) {
    const { ended } = startupService.getInitStatus();
    if (ended) return;

    try {
      await startupService.initClientSide(); // Only client side initialization (language, theme...)
    } catch (e: unknown) {
      throw e;
    }
  }

  public static canMatch() {
    return PublicInitializationGuard.isInitialized();
  }
}
