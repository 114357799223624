import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { HttpBackend, HttpInterceptorFn, provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Provider, Type, importProvidersFrom } from '@angular/core';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MsalConfig } from '@portal-shared/app/authentication/msal.config';
import { PortalErrorHandler } from '@portal-shared/app/handlers/portal-error-handler';
import { BaseUrlInterceptor } from '@portal-shared/app/interceptors/base-url.interceptor';
import { CacheBusterInterceptor } from '@portal-shared/app/interceptors/cache-buster.interceptor';
import { DomainInterceptor } from '@portal-shared/app/interceptors/domain.interceptor';
import { HubConnectionInterceptor } from '@portal-shared/app/interceptors/hub-connection.interceptor';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { AuthInterceptor } from './app/authentication/auth.interceptor';
import { PeriodDateRangeSelectionStrategy } from './app/components/inputs/period-picker/period-date-range-selection-strategy';
import { BaseSettingsDto } from './app/dtos/base-settings.dto';
import { ApiCacheInterceptor } from './app/interceptors/api-cache-interceptor';
import { BaseDraggingService } from './app/services/base-dragging.service';
import { BaseSettingService } from './app/services/base-settings.service';
import { PeriodService } from './app/services/period.service';
import { PortalSettingsService } from './app/services/portal-settings.service';
import { StartupService } from './app/services/startup.service';
import { FeatureFlagsT } from './app/typings/feature-flags.type';
import { IEnvironment } from './environments/environment.interface';

function httpLoaderFactory(httpBackend: HttpBackend) {
  return new MultiTranslateHttpLoader(httpBackend, ['/portal-shared/assets/i18n/portal/', '/assets/i18n/']);
}

export function getHttpClientProvider(interceptors: HttpInterceptorFn[] = []) {
  return provideHttpClient(
    withInterceptors([
      BaseUrlInterceptor,
      AuthInterceptor,
      DomainInterceptor,
      HubConnectionInterceptor,
      CacheBusterInterceptor,
      ApiCacheInterceptor,
      ...interceptors
    ])
  );
}

export function getEnvironmentProviders(environment: IEnvironment) {
  return importProvidersFrom(
    FontAwesomeModule,
    MatLuxonDateModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.build === 'prod',
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  );
}

export function getPortalProviders(
  environment: IEnvironment,
  draggingService: Type<BaseDraggingService> | null,
  settingsService: Type<BaseSettingService<FeatureFlagsT, BaseSettingsDto<FeatureFlagsT>>> | null,
  startupService: Type<StartupService> | null,
  periodService: Type<PeriodService> | null
): Provider[] {
  return [
    {
      provide: APP_INITIALIZER,
      useFactory: (httpBackend: HttpBackend) => StartupService.initializeApp(httpBackend, environment),
      // We cannot inject HttpClient due DI order.
      // We do not want to provide interceptors before the app initializer's end.
      deps: [HttpBackend],
      multi: true
    },
    { provide: BaseDraggingService, useExisting: draggingService },
    { provide: PortalSettingsService, useExisting: settingsService },
    { provide: StartupService, useExisting: startupService },
    { provide: PeriodService, useExisting: periodService },
    { provide: OverlayContainer, useExisting: FullscreenOverlayContainer },
    { provide: ErrorHandler, useClass: PortalErrorHandler },
    ...MsalConfig.Providers,
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useExisting: PeriodDateRangeSelectionStrategy
    },
    provideAnimations()
  ];
}
