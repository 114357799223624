import { Injectable } from '@angular/core';
import { AllyaDomainDatabase } from '@databases/allya-domain.database';
import { ConnectedUsersService } from '@portal-shared/app/services/connected-users.service';
import { DomainService } from '@portal-shared/app/services/domain.service';
import { InsightsService } from '@portal-shared/app/services/insights.service';
import { LanguageService } from '@portal-shared/app/services/language.service';
import { LoginService } from '@portal-shared/app/services/login.service';
import { NotificationService } from '@portal-shared/app/services/notification.service';
import { PortalLoggerService } from '@portal-shared/app/services/portal-logger.service';
import { StartupService as PortalStartupService } from '@portal-shared/app/services/startup.service';
import { ThemeService } from '@portal-shared/app/services/theme.service';
import { ToastService } from '@portal-shared/app/services/toast.service';
import { UsersService } from '@portal-shared/app/services/users.service';
import { PlannablesFiltersService } from '@resource-management/task-board/header/plannables-filter/plannables-filters.service';
import { AllyaSettingService } from '@services/allya-setting.service';
import { PlanningBoardPeriodService } from '@services/planning-board-period.service';
import { AssignmentBoardFiltersService } from '@shared/components/assignment-board/assignment-board-header/assignment-board-filters/assignment-board-filters.service';
import { RequestBoardFiltersService } from '@shared/components/request-board/request-board-header/request-list-filters/request-board-filters.service';
import { DraggingService } from './dragging.service';
import { AllyaNotificationClient } from './notifications/allya-notification-client';

@Injectable({
  providedIn: 'root'
})
export class StartupService extends PortalStartupService {
  public constructor(
    logger: PortalLoggerService,
    themeService: ThemeService,
    allyaSettingService: AllyaSettingService,
    loginService: LoginService,
    languageService: LanguageService,
    draggingService: DraggingService,
    insightsService: InsightsService,
    domainService: DomainService,
    connectedUsersService: ConnectedUsersService,
    userService: UsersService,
    notificationService: NotificationService,
    toastService: ToastService,
    private plannablesFilterService: PlannablesFiltersService,
    private planningBoardPeriodService: PlanningBoardPeriodService,
    private assignmentBoardFiltersService: AssignmentBoardFiltersService,
    private requestBoardFiltersService: RequestBoardFiltersService,
    private allyaNotificationClient: AllyaNotificationClient
  ) {
    super(
      logger,
      themeService,
      loginService,
      languageService,
      insightsService,
      allyaSettingService,
      userService,
      toastService,
      notificationService,
      connectedUsersService,
      domainService,
      draggingService
    );
  }

  public async startDomain(domainKey: string) {
    AllyaDomainDatabase.initialize(domainKey);

    void this.planningBoardPeriodService.setPeriodColumnsCount();
    this.planningBoardPeriodService.manageVisiblePeriods();
    this.assignmentBoardFiltersService.init();
    this.requestBoardFiltersService.init();
    this.plannablesFilterService.init();

    await this.initWebSockets(() => [this.allyaNotificationClient.initialize()]);
  }
}
