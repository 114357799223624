import { HttpEvent, HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { isNil } from 'lodash-es';
import { Observable, of, share, tap } from 'rxjs';
import { HttpCacheService, ngCacheHeader } from '../services/http-cache.service';

const pendingQueries = new Map<string, Observable<HttpEvent<unknown>>>();

export const ApiCacheInterceptor: HttpInterceptorFn = (req, next) => {
  if (req.method !== 'GET' || !req.url.includes('api')) {
    return next(req);
  }

  const cacheKey = req.headers.get(ngCacheHeader);
  req.headers.delete(ngCacheHeader);

  if (isNil(cacheKey)) {
    return next(req);
  }

  const url = req.urlWithParams;
  const cacheHit = HttpCacheService.getCacheEntry(cacheKey, url);

  if (cacheHit) {
    return of(cacheHit);
  }

  //Avoid sending 2 calls for the same url. If an api call is currently pending
  //the result will be sent to the second call too! (shared observable)
  if (pendingQueries.has(url)) {
    return pendingQueries.get(url)!;
  }

  const observable = next(req).pipe(share());
  pendingQueries.set(url, observable);

  return observable.pipe(
    tap(response => {
      if (response instanceof HttpResponse && response.status === 200) {
        HttpCacheService.setCacheEntry(cacheKey, url, response);
        pendingQueries.delete(url);
      }
    })
  );
};
