import { inject } from '@angular/core';
import { StartupService } from '@portal-shared/app/services/startup.service';

export class InitializationGuard {
  private static async isInitialized(startupService = inject(StartupService)) {
    const { ended } = startupService.getInitStatus();
    if (ended) return;

    try {
      await startupService.start();
    } catch (e: unknown) {
      throw e;
    }
  }

  public static canMatch() {
    return InitializationGuard.isInitialized();
  }
}
