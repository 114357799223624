import { inject } from '@angular/core';
import { LoginService } from '@portal-shared/app/services/login.service';
import { PortalGroupService } from '../services/portal-group.service';

export class AdminGuard {
  private static async isAdmin(loginService = inject(LoginService), portalGroupService = inject(PortalGroupService)) {
    const isAdmin = loginService.isJovacoPortalAdmin() || portalGroupService.amIAnAdmin();
    if (!isAdmin) {
      await loginService.goToUnauthorized();
    }

    return isAdmin;
  }

  public static canMatch() {
    return AdminGuard.isAdmin();
  }
}
