import { Routes } from '@angular/router';
import { ViewContext } from '@core/enums/view-context.enum';
import { AdminGuard } from '@portal-shared/app/authentication/admin.guard';
import { AuthGuard } from '@portal-shared/app/authentication/auth.guard';
import { PublicInitializationGuard } from '@portal-shared/app/authentication/client-initialization.guard';
import { DomainGuard } from '@portal-shared/app/authentication/domain.guard';
import { InitializationGuard } from '@portal-shared/app/authentication/initialization.guard';

export const routes: Routes = [
  {
    path: '401',
    canMatch: [() => PublicInitializationGuard.canMatch()],
    loadComponent: () =>
      import('@portal-shared/app/pages/unauthorized/unauthorized.component').then(m => m.UnauthorizedComponent)
  },
  {
    path: 'logout',
    canMatch: [() => PublicInitializationGuard.canMatch()],
    loadComponent: () => import('@portal-shared/app/pages/logout/logout.component').then(m => m.LogoutComponent)
  },
  {
    path: '404',
    canMatch: [() => PublicInitializationGuard.canMatch()],
    loadComponent: () =>
      import('@portal-shared/app/pages/page-not-found/page-not-found.component').then(m => m.PageNotFoundComponent)
  },
  {
    path: '',
    canMatch: [() => InitializationGuard.canMatch()],
    children: [
      {
        path: '',
        redirectTo: ViewContext.ResourceGrid,
        pathMatch: 'full'
      },
      {
        path: ViewContext.ResourceGrid,
        loadComponent: () =>
          import('./resource-management/resource-management.component').then(m => m.ResourceManagementComponent),
        canMatch: [() => AuthGuard.canMatch()], // First lazy load the module only if user is authenticated, else /401
        canActivate: [() => DomainGuard.canActivate()], // Then activate the pages if a domain could be selected, else /startup
        loadChildren: () => import('@resource-management/resource-management-routing').then(x => x.resourceRoutes)
      },
      {
        path: ViewContext.ProjectGrid,
        loadComponent: () =>
          import('./project-management/project-management.component').then(m => m.ProjectManagementComponent),
        canMatch: [() => AuthGuard.canMatch()], // First lazy load the module only if user is authenticated, else /401
        canActivate: [() => DomainGuard.canActivate()], // Then activate the pages if a domain could be selected, else /startup
        loadChildren: () => import('@project-management/project-management-routing').then(x => x.projectRoutes)
      },
      {
        path: 'startup',
        loadComponent: () =>
          import('@portal-shared/app/pages/startup-form/startup-form.component').then(m => m.StartupFormComponent),
        canMatch: [() => AuthGuard.canMatch()] // Add canActivate guard
      },
      {
        path: 'admin',
        canMatch: [() => AuthGuard.canMatch(), () => AdminGuard.canMatch()],
        canActivate: [() => DomainGuard.canActivate()], // Then activate the pages if a domain could be selected, else /startup
        loadChildren: () => import('@shared/pages/admin-page/admin.routes').then(m => m.adminRoutes)
      }
    ]
  },

  {
    path: '**',
    redirectTo: '404'
  }
];
