[
  {
    "id": "13b3d0ad-e6bc-4b16-b4f1-3d50ef6de542",
    "name": "Ressource Fictive 1",
    "defaultClassId": "33264cf0-3488-4876-aee4-ebdb28d87646",
    "departmentId": "8d2f479d-d910-417c-a5f8-e269123c6968",
    "capacity": 40
  },
  {
    "id": "07d40fb9-6252-4d2c-94da-a65745f891d9",
    "name": "Ressource Fictive 2",
    "defaultClassId": "fb7aaed5-ee46-4357-bc7c-40b078889346",
    "departmentId": "8d2f479d-d910-417c-a5f8-e269123c6968",
    "capacity": 40
  }
]
