[
  {
    "id": "ap32511b-6d01-4bff-a8b1-98647fd5ea8b",
    "periodId": 1,
    "assignmentId": "a352511b-6d01-4bff-a8b1-98647fd5ea8b",
    "plannedHours": 10
  },
  {
    "id": "ap12511b-6d01-4bff-a8b1-98647fd5ea8b",
    "periodId": 1,
    "assignmentId": "a152511b-6d01-4bff-a8b1-98647fd5ea8b",
    "plannedHours": 12
  },
  {
    "id": "ap22511b-6d01-4bff-a8b1-98647fd5ea8b",
    "periodId": 3,
    "assignmentId": "a252511b-6d01-4bff-a8b1-98647fd5ea8b",
    "plannedHours": 0
  },
  {
    "id": "ap42511b-6d01-4bff-a8b1-98647fd5ea8b",
    "periodId": 1,
    "assignmentId": "a452511b-6d01-4bff-a8b1-98647fd5ea8b",
    "plannedHours": 10
  },
  {
    "id": "ap52511b-6d01-4bff-a8b1-98647fd5ea8b",
    "periodId": 2,
    "assignmentId": "a552511b-6d01-4bff-a8b1-98647fd5ea8b",
    "plannedHours": 7
  },
  {
    "id": "ap62511b-6d01-4bff-a8b1-98647fd5ea8b",
    "periodId": 3,
    "assignmentId": "a652511b-6d01-4bff-a8b1-98647fd5ea8b",
    "plannedHours": 20
  },
  {
    "id": "ap72511b-6d01-4bff-a8b1-98647fd5ea8b",
    "periodId": 2,
    "assignmentId": "a752511b-6d01-4bff-a8b1-98647fd5ea8b",
    "plannedHours": 3
  },
  {
    "id": "ap82511b-6d01-4bff-a8b1-98647fd5ea8b",
    "periodId": 1,
    "assignmentId": "a852511b-6d01-4bff-a8b1-98647fd5ea8b",
    "plannedHours": 11
  },
  {
    "id": "ap92511b-6d01-4bff-a8b1-98647fd5ea8b",
    "periodId": 3,
    "assignmentId": "a952511b-6d01-4bff-a8b1-98647fd5ea8b",
    "plannedHours": 0
  }
]
