<div class="context-menu-body portal-box portal-box-border"
  [hidden]="!isOpen">
  <ul>
    @for (item of items; track item) {
      @if (item.separator === 'above') {
        <div class="separator"
        ></div>
      }
      <li [class.disabled]="isLoading || (item.disabled$ | async)"
        (click)="itemClick($event, item)">
        @if (item.titleKey) {
          <a>
            <div [translate]="item.titleKey"></div>
            @if (item.subTitleKey) {
              <div
                class="subtitle"
              [translate]="item.subTitleKey"></div>
            }
          </a>
        }
        @if (item.template) {
          <ng-container *ngTemplateOutlet="item.template"></ng-container>
        }
      </li>
      @if (item.separator === 'below') {
        <div class="separator"
        ></div>
      }
    }
  </ul>
</div>
