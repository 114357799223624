import { ErrorHandler, Injectable } from '@angular/core';
import { eSeverityLevel } from '@microsoft/applicationinsights-web';
import { MigrationError } from '@portal-shared/app/errors/migration.error';
import { ToastService } from '@portal-shared/app/services/toast.service';
import { PortalLoggerService } from '../services/portal-logger.service';

@Injectable()
export class PortalErrorHandler implements ErrorHandler {
  public constructor(
    private logger: PortalLoggerService,
    private toastService: ToastService
  ) {}

  public handleError(error: Error): void {
    this.logError(error);

    this.toastService.displayError(error);
  }

  private logError(error: Error & { rejection?: Error }) {
    const sourceError = error.rejection;

    if (sourceError && sourceError instanceof MigrationError) {
      this.logger.error(sourceError.message, sourceError, eSeverityLevel.Critical);
      return;
    }

    this.logger.error(this.getMessage(error), error);
    this.logger.warn(`${error.name} ${error.message} ${error.stack || ''}`);
  }

  private getMessage(error: Error) {
    const message = `[PortalErrorHandler]: ${error.message ?? error}`;
    try {
      return message.split('\n')[0];
    } catch {
      // catch all
      return message;
    }
  }
}
