import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { PortalSettingsService } from '@portal-shared/app/services/portal-settings.service';
import { from, switchMap } from 'rxjs';

export const BaseUrlInterceptor: HttpInterceptorFn = (req, next) => {
  const portalSettingsService = inject(PortalSettingsService);

  if (!req.url.includes('api')) {
    return next(req);
  }

  return from(portalSettingsService.getApiEndpoint()).pipe(
    switchMap(apiEndpoint => {
      const requestUrl = req.url.replace('/api', 'api');
      const clone = req.clone({
        url: `${apiEndpoint}/${requestUrl}`
      });
      return next(clone);
    })
  );
};
