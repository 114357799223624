[
  {
    "id": "a352511b-6d01-4bff-a8b1-98647fd5ea8b",
    "resourceId": "13b3d0ad-e6bc-4b16-b4f1-3d50ef6de542",
    "projectId": "6b065ad8-8833-4999-81ed-1693e0967030",
    "classId": "PA",
    "departmentId": "293c1346-2758-4070-92ed-0605446ba683"
    
  },
  {
    "id": "a152511b-6d01-4bff-a8b1-98647fd5ea8b",
    "projectId": "6b065ad8-8833-4999-81ed-1693e0967030",
    "classId": "PA",
    "departmentId": "293c1346-2758-4070-92ed-0605446ba683"
  },
  {
    "id": "a252511b-6d01-4bff-a8b1-98647fd5ea8b",
    "projectId": "6b065ad8-8833-4999-81ed-1693e0967030",
    "classId": "QA",
    "departmentId": "293c1346-2758-4070-92ed-0605446ba683"
  },
  {
    "id": "a452511b-6d01-4bff-a8b1-98647fd5ea8b",
    "resourceId": "13b3d0ad-e6bc-4b16-b4f1-3d50ef6de542",
    "projectId": "0ff00000-0000-0000-0000-000000000000",
    "classId": "",
    "departmentId": "293c1346-2758-4070-92ed-0605446ba683"
  },
  {
    "id": "a552511b-6d01-4bff-a8b1-98647fd5ea8b",
    "resourceId": "07d40fb9-6252-4d2c-94da-a65745f891d9",
    "projectId": "6b065ad8-8833-4999-81ed-1693e0967030",
    "classId": "",
    "departmentId": "293c1346-2758-4070-92ed-0605446ba683"
  },
  {
    "id": "a652511b-6d01-4bff-a8b1-98647fd5ea8b",
    "resourceId": "07d40fb9-6252-4d2c-94da-a65745f891d9",
    "projectId": "7d37bb68-0958-4e0f-83a9-78d565e71a77",
    "classId": "QA",
    "departmentId": "293c1346-2758-4070-92ed-0605446ba683"
  },
  {
    "id": "a752511b-6d01-4bff-a8b1-98647fd5ea8b",
    "resourceId": "1a6db110-9cdf-4305-8512-a0b697bf8193",
    "projectId": "fd24addd-dad0-4335-b652-7dbdcc3176e8",
    "classId": "PM",
    "departmentId": "293c1346-2758-4070-92ed-0605446ba683"
  },
  {
    "id": "a852511b-6d01-4bff-a8b1-98647fd5ea8b",
    "resourceId": "1a6db110-9cdf-4305-8512-a0b697bf8193",
    "projectId": "3af4c973-1228-49fa-82f4-6cc04c372f2d",
    "classId": "PM",
    "departmentId": "293c1346-2758-4070-92ed-0605446ba683"
  },
  {
    "id": "a952511b-6d01-4bff-a8b1-98647fd5ea8b",
    "resourceId": "1a6db110-9cdf-4305-8512-a0b697bf8193",
    "projectId": "0ff00000-0000-0000-0000-000000000000",
    "classId": "",
    "departmentId": "293c1346-2758-4070-92ed-0605446ba683"
  }
]
