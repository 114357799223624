import { HttpInterceptorFn, HttpRequest } from '@angular/common/http';

import { WindowHelper } from '@portal-shared/app/helpers/window.helper';

export const CacheBusterInterceptor: HttpInterceptorFn = (req, next) => {
  const startupDate = new Date().valueOf().toString();

  if (!WindowHelper.isCypress) {
    return next(req);
  }

  const paramKey = 't';

  const modifiedReq: HttpRequest<unknown> = req.clone({
    params: req.params.append(paramKey, startupDate)
  });
  return next(modifiedReq);
};
