import { HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { domainKeyHeaderName } from '../constants/domain-key-header-name.const';
import { DomainService } from '../services/domain.service';

export const DomainInterceptor: HttpInterceptorFn = (req, next) => {
  const domainService = inject(DomainService);

  if (!req.url.includes('api')) {
    return next(req);
  }

  const domain = domainService.getDomainKeyOrDefault();

  if (!domain) return next(req);

  const modifiedReq: HttpRequest<unknown> = req.clone({
    headers: req.headers.set(domainKeyHeaderName, domain)
  });
  return next(modifiedReq);
};
