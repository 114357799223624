import { inject } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DomainService } from '@portal-shared/app/services/domain.service';
import { PortalLoggerService } from '@portal-shared/app/services/portal-logger.service';

export class DomainGuard {
  private static async hasAccessToDomain(route = inject(ActivatedRoute), domainService = inject(DomainService)) {
    const routeSnapshot = route.snapshot;

    const routedDomainKey = routeSnapshot
      ? DomainGuard.getDomainKeyFromRouteParams(routeSnapshot.queryParams)
      : undefined;
    const domainKey = routedDomainKey ?? domainService.getDomainKeyOrDefault();

    //1. We have a domain key, Check for access
    if (domainKey) {
      const hasAccessToRoutedDomainKey = await domainService.hasUserAccessToDomain(domainKey);
      if (hasAccessToRoutedDomainKey) {
        domainService.setDomainKey(domainKey);
      }

      return hasAccessToRoutedDomainKey || DomainGuard.accessDenied(domainKey);
    }

    //2. We don't have domainKey. Check if
    //   we only have a single domain and redirect to DomainGuard domain.
    const myDomains = await domainService.getMyDomains();
    if (myDomains.length === 1) {
      domainService.setDomainKey(myDomains[0].key);
      return true;
    }

    //we haven't specified any domain in a multidomain setup, redirect to startup.
    await domainService.redirectToStartUp();
    return false;
  }

  private static getDomainKeyFromRouteParams(params: Params, domainService = inject(DomainService)) {
    const domainKey = params[domainService.domainKeyParameterName] as string | undefined;
    return domainKey?.toUpperCase();
  }

  private static async accessDenied(
    domainKey: string,
    domainService = inject(DomainService),
    logger = inject(PortalLoggerService)
  ) {
    logger.error(`${DomainGuard.name}: Unauthorized access to domain ${domainKey}, Redirecting to Startup.`);
    domainService.clearDomainFromStore();
    await domainService.redirectToStartUp(true);
    return false;
  }

  public static canActivate() {
    return DomainGuard.hasAccessToDomain();
  }
}
