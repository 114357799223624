[
  {
    "id": "293c1346-2758-4070-92ed-0605446ba683",
    "divisionId": "8ebab9e3-d8b0-47fa-8d73-2c14058e8a3d",
    "serviceId": "90f73a93-a296-4f72-865a-461ca1765aff",
    "code": "02-02",
    "description": "Developement des Affaires",
    "synchronizationAt": "0"
  },
  {
    "id": "3c222194-2db9-46f9-9250-4028948f0090",
    "divisionId": "8ebab9e3-d8b0-47fa-8d73-2c14058e8a3d",
    "serviceId": "699e531a-8c8f-417f-9a46-4a663524d978",
    "code": "02-01",
    "description": "Administration",
    "synchronizationAt": "0"
  },
  {
    "id": "f8b03813-533f-4ead-b7ad-95d95b8a93da",
    "divisionId": "8ebab9e3-d8b0-47fa-8d73-2c14058e8a3d",
    "serviceId": "35f33807-c53d-4336-a312-98d350b01aec",
    "code": "02-15",
    "description": "Service Conseil",
    "synchronizationAt": "0"
  },
  {
    "id": "04df086b-1bd8-4791-8107-5a681bad334a",
    "divisionId": "8ebab9e3-d8b0-47fa-8d73-2c14058e8a3d",
    "serviceId": "bbda48d1-39a1-4cb2-a0b5-f073c0318589",
    "code": "02-10",
    "description": "Ingénerie Civil",
    "synchronizationAt": "0"
  },
  {
    "id": "447eeb7d-b90f-4187-ab36-b3964d08a635",
    "divisionId": "e5223b41-77c1-4f27-8788-647a38a57fd6",
    "serviceId": "90f73a93-a296-4f72-865a-461ca1765aff",
    "code": "01-02",
    "description": "Dévelopment des Affaires",
    "synchronizationAt": "0"
  },
  {
    "id": "4995fa59-5de6-4301-a61f-8e32f77aece7",
    "divisionId": "e5223b41-77c1-4f27-8788-647a38a57fd6",
    "serviceId": "699e531a-8c8f-417f-9a46-4a663524d978",
    "code": "01-01",
    "description": "Administration",
    "synchronizationAt": "0"
  },
  {
    "id": "191e8f39-7ff3-41d6-9aac-bc784d3a425c",
    "divisionId": "e5223b41-77c1-4f27-8788-647a38a57fd6",
    "serviceId": "64ebbdfb-aba7-4243-b5a0-6b581312ea94",
    "code": "01-70",
    "description": "Support",
    "synchronizationAt": "0"
  },
  {
    "id": "2b6fc6c5-6c98-40ed-8bd2-ce3068eb21df",
    "divisionId": "e5223b41-77c1-4f27-8788-647a38a57fd6",
    "serviceId": "35f33807-c53d-4336-a312-98d350b01aec",
    "code": "01-15",
    "description": "Service Conseil",
    "synchronizationAt": "0"
  },
  {
    "id": "8d2f479d-d910-417c-a5f8-e269123c6968",
    "divisionId": "e5223b41-77c1-4f27-8788-647a38a57fd6",
    "serviceId": "3277da24-57b2-438e-a749-d57e8ba6197f",
    "code": "01-30",
    "description": "Dévelopment",
    "synchronizationAt": "0"
  },
  {
    "id": "c39f39b3-b3fa-4110-b66c-034841b25a75",
    "divisionId": "e5223b41-77c1-4f27-8788-647a38a57fd6",
    "serviceId": "bbda48d1-39a1-4cb2-a0b5-f073c0318589",
    "code": "01-10",
    "description": "Ingénierie Civil",
    "synchronizationAt": "0"
  }
]
