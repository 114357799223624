[
  {
    "key": "DEMO",
    "databaseStatus": "Created"
  },
  {
    "key": "JOVTE",
    "databaseStatus": "Created"
  }
]
