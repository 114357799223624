import assignmentPeriods from '@assets/mock/assignment-periods/assignment-periods.mock.json';
import assignments from '@assets/mock/assignments/assignments.mock.json';
import classes from '@assets/mock/class/classes.mock.json';
import departments from '@assets/mock/department/department.mock.json';
import fictionalResources from '@assets/mock/fictional-resource/fictional-resource.mock.json';
import plannables from '@assets/mock/plannable/plannable.mock.json';
import projects from '@assets/mock/project/project.mock.json';
import requestedResourceRows from '@assets/mock/requested-resource/requested-resource.mock.json';
import resources from '@assets/mock/resource/resource.mock.json';
import me from '@assets/mock/users/me.json';
import { FictionalResourceDto } from '@core/services/dtos/fictional-resource.dto';
import { PlannableDto } from '@core/services/dtos/plannable.dto';
import { ClassDto } from '@portal-shared/app/dtos/class.dto';
import { DepartmentDto } from '@portal-shared/app/dtos/department.dto';
import { DomainDto } from '@portal-shared/app/dtos/domain.dto';
import { ProjectDto } from '@portal-shared/app/dtos/project.dto';
import { ResourceDto } from '@portal-shared/app/dtos/resource.dto';
import { UserDto } from '@portal-shared/app/dtos/user.dto';
import domains from '@portal-shared/assets/mock/domains/domains.mock.json';
import { AssignmentPeriodDto } from '@services/dtos/assignment-period.dto';
import { AssignmentDto } from '@services/dtos/assignment.dto';
import { RequestedResourceRowDto } from '@services/dtos/requested-resource-row.dto';
import { periodsMock } from '@testing/helpers/periods.mock';

export const MOCK_DELAY = 500;
export type MockMethod = 'POST' | 'GET' | 'ALL';
export type MockEntity = {
  path: string;
  data: unknown;
  disabledFor?: MockMethod[]; //http methods
};

export const MOCKED_ENTITIES: MockEntity[] = [
  {
    path: 'resources',
    data: resources as ResourceDto[],
    disabledFor: ['ALL']
  },
  {
    path: 'requestedResources',
    data: requestedResourceRows as RequestedResourceRowDto[],
    disabledFor: ['ALL']
  },
  {
    path: 'requestedResources',
    data: fictionalResources as FictionalResourceDto[],
    disabledFor: ['ALL']
  },
  {
    path: 'classes',
    data: classes as unknown as ClassDto[],
    disabledFor: ['ALL']
  },
  {
    path: 'departments',
    data: departments as unknown as DepartmentDto[],
    disabledFor: ['ALL']
  },
  {
    path: 'plannable',
    data: plannables as PlannableDto[],
    disabledFor: ['ALL']
  },
  {
    path: 'projects',
    data: projects as unknown as ProjectDto[],
    disabledFor: ['ALL']
  },
  {
    path: 'assignments',
    data: assignments as AssignmentDto[],
    disabledFor: ['GET', 'POST']
  },
  {
    path: 'assignmentperiods',
    data: assignmentPeriods as AssignmentPeriodDto[],
    disabledFor: ['GET', 'POST']
  },

  {
    path: 'periods', // Must be below 'assignment-periods'
    data: periodsMock,
    disabledFor: ['ALL']
  },

  {
    path: 'users/me',
    data: me as UserDto,
    disabledFor: ['ALL']
  },
  {
    path: '/drop',
    data: {},
    disabledFor: ['ALL']
  },
  {
    path: '/Users/me/Domains',
    data: domains as DomainDto[],
    disabledFor: ['ALL']
  }
];
