import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';

import { connectionIdHeaderName } from '@portal-shared/app/constants/connection-id-header-name.const';
import { NotificationService } from '@portal-shared/app/services/notification.service';

export const HubConnectionInterceptor: HttpInterceptorFn = (req, next) => {
  const notificationService = inject(NotificationService);

  const connectionId = notificationService.getHubConnectionId();

  if (connectionId) {
    req = req.clone({
      headers: req.headers.set(connectionIdHeaderName, connectionId)
    });
  }

  return next(req);
};
