import { HttpInterceptorFn, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { PortalLoggerService } from '@portal-shared/app/services/portal-logger.service';
import { StringDictionary } from '@portal-shared/app/typings/dictionary.type';
import { UuidUtils } from '@portal-shared/app/utils/uuid.utils';
import { AllyaSettingService } from '@services/allya-setting.service';
import { extend } from 'lodash-es';
import { Observable, from, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MOCKED_ENTITIES, MockEntity, MockMethod } from './http-mock.config';

const entities = MOCKED_ENTITIES;

export const HttpMockRequestInterceptor: HttpInterceptorFn = (req, next) => {
  const allyaSettingService = inject(AllyaSettingService);
  const logger = inject(PortalLoggerService);

  if (!req.url.includes('api')) {
    return next(req);
  }

  const $settings = from(allyaSettingService.getSettings());
  return $settings.pipe(
    switchMap(settings => {
      const mockEndpointHandler = settings.enableMock ? selectHandler(req) : undefined;

      if (mockEndpointHandler) {
        logger.warn(`MOCK: ${req.urlWithParams}`);
        return mockEndpointHandler(req);
      }

      return next(req);
    })
  );
};

function isMockDisabled(entity: MockEntity, method?: MockMethod) {
  if (environment.build === 'test') {
    return false; // always mock in test
  }

  const isDisabled = entity.disabledFor && entity.disabledFor?.includes('ALL');
  const isSpecificMethodDisabled = method ? entity.disabledFor?.includes(method) : false;
  return isDisabled || isSpecificMethodDisabled;
}

/**
 * Determines if a mock exists for the targeted REST entity.
 * If so returns a mocked response handler, else returns null.
 * @param request: HttpRequest<unknown>
 * @returns ((request: HttpRequest<unknown>) => Observable<HttpResponse<unknown>>) | null
 */
function selectHandler(
  request: HttpRequest<unknown>
): ((request: HttpRequest<unknown>) => Observable<HttpResponse<unknown>>) | null {
  let entityFound = false;
  const mockedEntity = entities.find(e => {
    if (entityFound) {
      return false;
    }
    entityFound = request.url.includes(e.path);
    return entityFound && !isMockDisabled(e, request.method as MockMethod);
  });
  if (!mockedEntity) {
    return null;
  }

  switch (request.method) {
    case 'GET':
      return getMockedResponse(mockedEntity);
    case 'POST':
      return createMockedResponse();
    case 'PUT':
    case 'PATCH':
      return editMockedResponse();
    case 'DELETE':
      return () =>
        of(
          new HttpResponse({
            status: 204,
            body: true
          })
        );
    default:
      return null;
  }
}

function getMockedResponse(entity: MockEntity): (request: HttpRequest<unknown>) => Observable<HttpResponse<unknown>> {
  return (request: HttpRequest<unknown>) => {
    const body = Array.isArray(entity.data)
      ? entity.data.filter((o: StringDictionary<string>) => entityFitsParams(o, request.params))
      : entity.data;
    return of(
      new HttpResponse({
        status: 200,
        body
      })
    );
  };
}

function createMockedResponse(): (request: HttpRequest<unknown>) => Observable<HttpResponse<unknown>> {
  const generatedId = { id: UuidUtils.generateUuid() };
  return (request: HttpRequest<unknown>) =>
    of(
      new HttpResponse({
        status: 200,
        body: extend(request.body, generatedId)
      })
    );
}

function editMockedResponse(): (request: HttpRequest<unknown>) => Observable<HttpResponse<unknown>> {
  return (request: HttpRequest<unknown>) =>
    of(
      new HttpResponse({
        status: 200,
        body: request.body
      })
    );
}

function entityFitsParams(entity: StringDictionary<string>, params: HttpParams): boolean {
  return params.keys().every(k => !(k in entity) || params.getAll(k)?.some(value => value === entity[k]?.toString()));
}
