[
  {
    "id": "13b3d0ad-e6bc-4b16-b4f1-3d50ef6de542",
    "name": "Alex Harvey",
    "code": "0004",
    "email": "alexharvey@jovaco.ca",
    "defaultClassId": "33264cf0-3488-4876-aee4-ebdb28d87646",
    "departmentId": "8d2f479d-d910-417c-a5f8-e269123c6968",
    "isActive": true,
    "capacity": 40,
    "workEnded": null
  },
  {
    "id": "07d40fb9-6252-4d2c-94da-a65745f891d9",
    "name": "James Bond",
    "code": "0206",
    "email": null,
    "defaultClassId": "fb7aaed5-ee46-4357-bc7c-40b078889346",
    "departmentId": "8d2f479d-d910-417c-a5f8-e269123c6968",
    "isActive": true,
    "capacity": 40,
    "workEnded": null
  },
  {
    "id": "1a6db110-9cdf-4305-8512-a0b697bf8193",
    "name": "Roger Federer",
    "code": "0012",
    "email": null,
    "defaultClassId": "33264cf0-3488-4876-aee4-ebdb28d87646",
    "departmentId": "c39f39b3-b3fa-4110-b66c-034841b25a75",
    "isActive": false,
    "capacity": 40,
    "workEnded": "2022-10-30T23:59:59-05:00"
  },
  {
    "id": "98bd7f79-4352-47b2-b11a-a69ae569be75",
    "name": "Sulu Hykaru",
    "code": "0198",
    "email": null,
    "defaultClassId": "20f31426-b416-493c-9368-f97ab94c7d4e",
    "departmentId": "8d2f479d-d910-417c-a5f8-e269123c6968",
    "isActive": false,
    "capacity": 40,
    "workEnded": "2022-10-30T23:59:59-05:00"
  },
  {
    "id": "22cdc4b2-6322-433d-adba-05e552380055",
    "name": "Leonard McCoy",
    "code": "0102",
    "email": null,
    "defaultClassId": "8d9b380e-d685-4eae-9a8b-f7d5b6899c87",
    "departmentId": "c39f39b3-b3fa-4110-b66c-034841b25a75",
    "isActive": true,
    "capacity": 40
  },
  {
    "id": "2fecb122-25ad-4f6a-a8e1-0b2c7893c744",
    "name": "Indiana Jones",
    "code": "0205",
    "email": null,
    "defaultClassId": "fb7aaed5-ee46-4357-bc7c-40b078889346",
    "departmentId": "3c222194-2db9-46f9-9250-4028948f0090",
    "isActive": true,
    "capacity": 40
  },
  {
    "id": "5204804b-3287-4b5e-a3af-0d69c90c6c18",
    "name": "Beverly Crusher",
    "code": "0200",
    "email": null,
    "defaultClassId": "33264cf0-3488-4876-aee4-ebdb28d87646",
    "departmentId": "c39f39b3-b3fa-4110-b66c-034841b25a75",
    "isActive": true,
    "capacity": 40
  },
  {
    "id": "a794a2e6-b0a5-4a71-8020-18aba947e8ab",
    "name": "Jean-Luc Picard",
    "code": "0202",
    "email": "clamothe@jovaco.ca",
    "defaultClassId": "9edb2689-f875-4070-938e-be4c2d82e46f",
    "departmentId": "c39f39b3-b3fa-4110-b66c-034841b25a75",
    "isActive": true,
    "capacity": 40
  },
  {
    "id": "30d9f07f-0315-46ca-9e34-33152d1f9089",
    "name": "Phil Mickelson",
    "code": "0015",
    "email": null,
    "defaultClassId": "071ad0a1-a540-47f0-9f2e-1795403594e5",
    "departmentId": "c39f39b3-b3fa-4110-b66c-034841b25a75",
    "isActive": true,
    "capacity": 40
  },
  {
    "id": "eb9d8b2b-5fdc-4554-b5d0-4a8ae49e6be1",
    "name": "James Kirk",
    "code": "0021",
    "email": null,
    "defaultClassId": "8d9b380e-d685-4eae-9a8b-f7d5b6899c87",
    "departmentId": "c39f39b3-b3fa-4110-b66c-034841b25a75",
    "isActive": true,
    "capacity": 40
  },
  {
    "id": "c205443e-9461-4a2b-bb09-5e54bdbbe4e0",
    "name": "Novak Djokovic",
    "code": "0013",
    "email": null,
    "defaultClassId": "2e08a5bc-894b-40ed-ab18-f533cadc8c38",
    "departmentId": "2b6fc6c5-6c98-40ed-8bd2-ce3068eb21df",
    "isActive": true,
    "capacity": 40
  },
  {
    "id": "a32d9ebb-13b3-49f8-892c-606c6c2d92b1",
    "name": "Oprah Winfrey",
    "code": "0204",
    "email": null,
    "defaultClassId": "fb7aaed5-ee46-4357-bc7c-40b078889346",
    "departmentId": "4995fa59-5de6-4301-a61f-8e32f77aece7",
    "isActive": true,
    "capacity": 40
  },
  {
    "id": "ffc7e108-d35d-4853-9e33-630328e84693",
    "name": "Backlund, Bob",
    "code": "0001",
    "email": "slavergne@jovacosolutions.onmicrosoft.com",
    "defaultClassId": "8d9b380e-d685-4eae-9a8b-f7d5b6899c87",
    "departmentId": "c39f39b3-b3fa-4110-b66c-034841b25a75",
    "isActive": true,
    "capacity": 40
  },
  {
    "id": "ee6c2341-0594-4817-9f61-683643ac2d52",
    "name": "Carey Price",
    "code": "0002",
    "email": null,
    "defaultClassId": "9246e129-8dba-45d7-aea6-cb125da09a96",
    "departmentId": "2b6fc6c5-6c98-40ed-8bd2-ce3068eb21df",
    "isActive": true,
    "capacity": 40
  },
  {
    "id": "98c8e464-7ae5-4931-9576-6e479f37e874",
    "name": "Raonic, Milos",
    "code": "0014",
    "email": null,
    "defaultClassId": "20f31426-b416-493c-9368-f97ab94c7d4e",
    "departmentId": "2b6fc6c5-6c98-40ed-8bd2-ce3068eb21df",
    "isActive": true,
    "capacity": 40
  },
  {
    "id": "19ec7606-ec75-46dc-bdb8-769d0688a383",
    "name": "Employee Modele",
    "code": "ZZMODELE",
    "email": null,
    "defaultClassId": "33264cf0-3488-4876-aee4-ebdb28d87646",
    "departmentId": "c39f39b3-b3fa-4110-b66c-034841b25a75",
    "isActive": true,
    "capacity": 40
  },
  {
    "id": "066ea6f8-1399-4bc8-ad48-7ceb1325d5c2",
    "name": "Visite",
    "code": "VISITE",
    "email": null,
    "defaultClassId": "00000000-0000-0000-0000-000000000000",
    "departmentId": "00000000-0000-0000-0000-000000000000",
    "isActive": true,
    "capacity": 40
  },
  {
    "id": "f3cc368d-fcc3-41d0-ae97-7e45e170de85",
    "name": "Mikhael Pencicola",
    "code": "0007",
    "email": null,
    "defaultClassId": "8d9b380e-d685-4eae-9a8b-f7d5b6899c87",
    "departmentId": "447eeb7d-b90f-4187-ab36-b3964d08a635",
    "isActive": true,
    "capacity": 40
  },
  {
    "id": "38f180f6-f3c4-4985-aafa-8728cc3481dc",
    "name": "Michelle Wie",
    "code": "0016",
    "email": null,
    "defaultClassId": "4743b3be-1f6a-4e9f-9042-83af522362a1",
    "departmentId": "8d2f479d-d910-417c-a5f8-e269123c6968",
    "isActive": true,
    "capacity": 40
  },
  {
    "id": "a4735f7d-2692-4bd9-a16f-9c6b448ab58b",
    "name": "Jennifer  Heil",
    "code": "0005",
    "email": "ngiele@jovaco.ca",
    "defaultClassId": "071ad0a1-a540-47f0-9f2e-1795403594e5",
    "departmentId": "2b6fc6c5-6c98-40ed-8bd2-ce3068eb21df",
    "isActive": true,
    "capacity": 40
  },
  {
    "id": "67334245-09a4-43cc-844b-a7cda32fb9b6",
    "name": " ",
    "code": "FI",
    "email": null,
    "defaultClassId": "00000000-0000-0000-0000-000000000000",
    "departmentId": "00000000-0000-0000-0000-000000000000",
    "isActive": true,
    "capacity": 40
  },
  {
    "id": "d1969658-69ce-4443-8060-b0d0510d963a",
    "name": "Donavan Truman",
    "code": "0008",
    "email": null,
    "defaultClassId": "73749231-ab48-4fcf-8e0b-7c2a3a5ad9f7",
    "departmentId": "447eeb7d-b90f-4187-ab36-b3964d08a635",
    "isActive": true,
    "capacity": 40
  },
  {
    "id": "8595b1ce-301d-4233-85e5-baaf4e6e3224",
    "name": "Sarah Burke",
    "code": "0010",
    "email": "jlanglois@jovaco.ca",
    "defaultClassId": "fb7aaed5-ee46-4357-bc7c-40b078889346",
    "departmentId": "c39f39b3-b3fa-4110-b66c-034841b25a75",
    "isActive": true,
    "capacity": 40
  },
  {
    "id": "2d81ad11-c113-48b6-aa0d-bd098797310c",
    "name": "Lindsey Vonn",
    "code": "0006",
    "email": null,
    "defaultClassId": "fb7aaed5-ee46-4357-bc7c-40b078889346",
    "departmentId": "3c222194-2db9-46f9-9250-4028948f0090",
    "isActive": true,
    "capacity": 40
  },
  {
    "id": "31c66e37-ba78-4bc2-9319-d763896ff575",
    "name": "Eric Guay",
    "code": "0011",
    "email": null,
    "defaultClassId": "eefd66b7-127c-4b82-a260-a451a9ce1c92",
    "departmentId": "c39f39b3-b3fa-4110-b66c-034841b25a75",
    "isActive": true,
    "capacity": 40
  },
  {
    "id": "4323ef25-3a75-4cd3-abf2-d8b0de609100",
    "name": "Weber, Shea",
    "code": "0003",
    "email": "jovaco@jovacosolutions.onmicrosoft.com",
    "defaultClassId": "33264cf0-3488-4876-aee4-ebdb28d87646",
    "departmentId": "8d2f479d-d910-417c-a5f8-e269123c6968",
    "isActive": true,
    "capacity": 40
  },
  {
    "id": "a698ca7b-3cc0-4dfe-b726-daeece221918",
    "name": "Joel Bandonere",
    "code": "0009",
    "email": null,
    "defaultClassId": "fb7aaed5-ee46-4357-bc7c-40b078889346",
    "departmentId": "f8b03813-533f-4ead-b7ad-95d95b8a93da",
    "isActive": true,
    "capacity": 40
  },
  {
    "id": "ee8a7825-e732-426e-ab38-e5a521eebaf3",
    "name": "Elvis Presley",
    "code": "0203",
    "email": null,
    "defaultClassId": "fd5898c5-26c8-49e1-aa69-b79e8bc504a2",
    "departmentId": "3c222194-2db9-46f9-9250-4028948f0090",
    "isActive": true,
    "capacity": 40
  },
  {
    "id": "6c1000a9-a5c9-4b45-b632-f827a08a0927",
    "name": "Bill Gates",
    "code": "0017",
    "email": "bill@jovaco.ca",
    "defaultClassId": "8d9b380e-d685-4eae-9a8b-f7d5b6899c87",
    "departmentId": "c39f39b3-b3fa-4110-b66c-034841b25a75",
    "isActive": true,
    "capacity": 40
  }
]
