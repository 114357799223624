[
  {
    "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "assignmentId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "name": "string",
    "createdBy": "string",
    "createdAt": "2024-06-11T17:00:09.914Z",
    "capacity": 0,
    "projectId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "departmentId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "classId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "totalHours": 0,
    "numberOfPeriods": 0,
    "projectCode": "string",
    "projectDescription": "string",
    "departmentCode": "string",
    "departmentDescription": "string",
    "classCode": "string",
    "classDescription": "string",
    "periodUsage": [],
    "assignmentPeriods": [
      {
        "periodId": 0,
        "plannedHours": 0,
        "realHours": 0,
        "start": "2024-06-11T17:00:09.914Z",
        "end": "2024-06-11T17:00:09.914Z"
      }
    ]
  }
]
