import { HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalConfig } from '@portal-shared/app/authentication/msal.config';
import { BaseComponent } from '@portal-shared/app/components/base-components/base.component';
import { InitializationStatus } from '@portal-shared/app/models/initialization-status.model';
import { DomainService } from '@portal-shared/app/services/domain.service';
import { InsightsService } from '@portal-shared/app/services/insights.service';
import { LanguageService } from '@portal-shared/app/services/language.service';
import { LoginService } from '@portal-shared/app/services/login.service';
import { NotificationService } from '@portal-shared/app/services/notification.service';
import { PortalLoggerService } from '@portal-shared/app/services/portal-logger.service';
import { ThemeService } from '@portal-shared/app/services/theme.service';
import { ToastService } from '@portal-shared/app/services/toast.service';
import { UsersService } from '@portal-shared/app/services/users.service';
import { FormatUtils } from '@portal-shared/app/utils/format.utils';
import { IEnvironment } from '@portal-shared/environments/environment.interface';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BaseDraggingService } from './base-dragging.service';
import { ConnectedUsersService } from './connected-users.service';
import { PortalSettingsService } from './portal-settings.service';

@Injectable()
export abstract class StartupService extends BaseComponent {
  private cachePromise?: Promise<unknown>;
  private startupPromise?: Promise<void>;
  private clientStartupPromise?: Promise<unknown[]>;
  private initStatus$ = new BehaviorSubject<InitializationStatus>({});

  public constructor(
    private logger: PortalLoggerService,
    private themeService: ThemeService,
    private loginService: LoginService,
    private languageService: LanguageService,
    private insightsService: InsightsService,
    private baseSettingsService: PortalSettingsService,
    private userService: UsersService,
    private toastService: ToastService,
    private notificationService: NotificationService,
    private connectedUsersService?: ConnectedUsersService,
    private domainService?: DomainService,
    private draggingService?: BaseDraggingService
  ) {
    super();
  }

  public static initializeApp(httpBackend: HttpBackend, environment: IEnvironment) {
    return () => {
      return MsalConfig.Configure(httpBackend, environment);
    };
  }

  public async start(): Promise<void> {
    try {
      if (!this.startupPromise) {
        this.startupPromise = this.startInternal();
      }

      await this.startupPromise;
    } catch (e: unknown) {
      this.setInitEnd(e as Error);
      throw e;
    } finally {
      this.startupPromise = undefined; // dont re-throw the error when retrying
    }
  }

  protected async startInternal(): Promise<void> {
    this.setInitStart();
    const loginUserPromise = this.loginUser();
    const settingsPromise = this.baseSettingsService.getSettings();
    const clientSidePromises = this.initClientSide();

    const settings = await settingsPromise;
    this.logger.info(`*****application startup version :${settings.version}*****`);

    this.insightsService.initialize(settings.insightsConnectionString);
    this.draggingService?.listen();

    await loginUserPromise;
    const domainKey = this.domainService?.getDomainKeyOrDefault();
    if (domainKey) {
      await this.startDomain(domainKey);
    }

    await clientSidePromises;

    this.setInitEnd();
  }

  public async initClientSide() {
    if (!this.clientStartupPromise) {
      const initSteps = [FormatUtils.initialize(), this.themeService.initialize(), this.languageService.initialize()];
      this.clientStartupPromise = Promise.all(initSteps);
    }
    return this.clientStartupPromise;
  }

  protected async initWebSockets(notificationSubscriptions: () => Subscription[]) {
    const { build } = this.baseSettingsService.getEnvironment();

    if (build !== 'test') {
      await this.notificationService.createHubConnection();

      //needs to be before connection to pick up any onConnect events
      if (this.connectedUsersService) {
        this.subscribe(...this.connectedUsersService.manageNotifications());
      }
      this.subscribe(...notificationSubscriptions());

      try {
        const disableWS = await this.baseSettingsService.getFeatureFlagValue('disableWebSockets');
        if (!disableWS) {
          await this.notificationService.connect();
          this.logger.info(`${StartupService.name}: notificationService webSockets connected`);
        }
      } catch {
        this.logger.error(`${StartupService.name}: notificationService webSockets connection failed`);
        this.toastService.displayWarning('core.errors.websocketDisconnected');
      }
    }
  }

  protected async loginUser() {
    await this.loginService.initialize();

    try {
      await this.domainService?.initialize();
    } catch (err) {
      this.logger.error('Failed to get current domain', err as Error);
      throw err;
    }
  }

  public abstract startDomain(domainKey: string): Promise<void>;

  protected async preloadCache(tasks: Promise<unknown>[]) {
    if (!this.cachePromise) {
      const promises = [this.userService.getMe(), ...tasks];
      this.cachePromise = Promise.all(promises);
    }

    try {
      await this.cachePromise;
      this.logger.info(`${StartupService.name}: Cache loaded`);
    } catch (e) {
      this.logger.error(`${StartupService.name} - preloadCache : `, e as Error);
    } finally {
      this.cachePromise = undefined;
    }
  }

  public getInitStatus() {
    return this.initStatus$.value;
  }

  public observeInitializationStatus() {
    return this.initStatus$.asObservable();
  }

  private setInitStart() {
    this.initStatus$.next({ ...this.initStatus$.value, started: true });
  }

  private setInitEnd(error?: Error) {
    this.initStatus$.next({ ...this.initStatus$.value, ended: true, error });
  }
}
