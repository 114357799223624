[
  {
    "id": "869d0968-35fe-4ecc-8a9d-11ea24ca25ab",
    "code": "PA",
    "description": "Programmeur(se)-Analyste",
    "synchronizationAt": "0"
  },
  {
    "id": "071ad0a1-a540-47f0-9f2e-1795403594e5",
    "code": "QA",
    "description": "Assurance Qualité",
    "synchronizationAt": "0"
  },
  {
    "id": "7ea443d0-02fc-4cdf-b6de-1f4d53242625",
    "code": "CT",
    "description": "Conseiller(ère) Technique",
    "synchronizationAt": "0"
  },
  {
    "id": "96a12f38-e106-4195-9ae9-3a46e41cec15",
    "code": "SM",
    "description": "Scrum Master",
    "synchronizationAt": "0"
  },
  {
    "id": "fb7aaed5-ee46-4357-bc7c-40b078889346",
    "code": "DIR",
    "description": "Directeur(rice) Innovation & Technologies Innovation & Technologies",
    "synchronizationAt": "0"
  },
  {
    "id": "53fbb8b0-5912-486f-a242-6804e3885a5c",
    "code": "PM",
    "description": "Chargé(e) de projet",
    "synchronizationAt": "0"
  },
  {
    "id": "2955c9e1-03d4-403c-8451-6ed49c0a6fd9",
    "code": "SA",
    "description": "Ventes",
    "synchronizationAt": "0"
  }
]
