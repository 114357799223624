@if (error) {
<portal-error-wrapper>
  <h1 title>{{ error.title }}</h1>

  <div description
       class="portal-box portal-box-padding portal-box-border stack">
    {{ error.stack ?? error.message }}
  </div>

  <div action
       class="action">
    <button class="portal-button"
            translate="core.errors.retry"
            (click)="retry()">
    </button>
  </div>
  <div footer
       class="footer">
    <p>{{'common.appName' | translate}} v{{ version }}</p>
    <p class="date">{{ date }}</p>
  </div>
</portal-error-wrapper>
} @else if (starting) {
<div class="loading"
     [@fadeInOut]>
  <div class="container">
    <div class="loader-container">
      <ng-content select="[loader]"></ng-content>
    </div>
    <ng-content select="[logo]"></ng-content>
    <div class="brand-name-image bottom-right"></div>
  </div>
</div>
} @else {
<router-outlet></router-outlet>
}

<portal-context-menu></portal-context-menu>
<portal-confirmation></portal-confirmation>
<portal-toast></portal-toast>
